import * as icon from '../Icon';
import styled from '@emotion/styled';

export const Departement = styled(function Departement(
  props: icon.SpecificIconProps
): React.ReactElement {
  return (
    <icon.Icon {...props} name="département" viewBox="-10 0 989 800">
      <path d="M434 6c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zM268 46c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm334 1c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zM139 160c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm592 1c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zM363 364c0-61 48-110 109-110s110 49 110 110-49 109-110 109-109-48-109-109zM78 320c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm712 1c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zM99 491c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm670 0c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zM196 632c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm476 1c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm-324 79c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38zm172 0c0-21 17-38 38-38s38 17 38 38-17 38-38 38-38-17-38-38z" />
    </icon.Icon>
  );
})``;
